<template>
  <div id="petition" v-if="ready">
    <p>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis esse
      mollitia id sit nam iusto deserunt voluptates cupiditate, adipisci
      repellat facere dolorum accusantium praesentium pariatur inventore labore
      omnis eveniet necessitatibus!
    </p>
    <form>
      <v-row>
        <v-col cols="12" sm="6" md="6" class="pb-0">
          <v-text-field
            filled
            v-model="name"
            :error-messages="nameErrors"
            label="Name"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6" class="pb-0">
          <v-text-field
            filled
            v-model="email"
            :error-messages="emailErrors"
            label="E-mail"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-radio-group v-model="liveHere">
        <v-radio label="I live in Plymouth" value="plymouth"></v-radio>
        <v-radio
          label="I don't live in Plymouth"
          value="non-plymouth"
        ></v-radio>
      </v-radio-group>
      <v-row v-if="liveHere === 'plymouth'">
        <v-col cols="12" class="pb-6">
          <v-text-field
            filled
            v-model="address"
            :error-messages="addressErrors"
            hint="This is just to prove residence; your address will not be shared with anyone"
            persistent-hint
            label="Address"
            required
            @input="$v.address.$touch()"
            @blur="$v.address.$touch()"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="pa-2">
        <v-spacer></v-spacer>
        <v-btn class="mr-4" @click="submit">
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </v-row>
    </form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "Petition",
  data: () => ({
    ready: false,
    name: "",
    email: "",
    address: "",
    select: null,
    liveHere: null
  }),
  mixins: [validationMixin],
  validations: {
    name: { required },
    email: { required, email },
    address: { required }
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.address.$dirty) return errors;
      !this.$v.address.required && errors.push("Address is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    }
  },
  methods: {
    submit() {
      this.$v.$touch();
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    }
  },
  mounted() {
    this.ready = true;
  }
};
</script>

<style lang="scss">
#petition {
  padding: 1em;
}
</style>
